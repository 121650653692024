<!--
* @description 头部导航栏
* @fileName HeaderBar.vue
* @author liulian
* @date 2023/06/28 15:38:29
-->
<template>
  <div class="header">
    <div class="header_contaienr flex-row">
      <div class="header_left flex-row align-center">
        <img class="header_logo" src="@/assets/images/logo@2x.png" alt="logo" />
        <span
          class="left_name flex-row font-color-black-241605 font-size-24 font-weight-500"
          >{{ $t(`HeaderBar.webtitle`) }}</span
        >
      </div>
      <!-- 导航区域 -->
      <div class="header_center" ref="headerCenter">
        <!-- 菜单区域 -->
        <div class="navs flex-row align-center">
          <div
            class="nav_item flex-row-center align-center"
            :class="
              (item.key === $route.query.type && hoverIndex === item.key) ||
              item.active ||
              isHome === item.key ||
              hoverIndex === item.key
                ? 'nav_active'
                : ''
            "
            v-for="(item, index) in navList"
            :key="index"
          >
            <div
              class="con cursor-pointer flex-row-center align-center"
              @click="clickNav(item)"
              @mouseenter="mouseenterEvent(item)"
            >
              <span
                class="nav_name font-size-18"
                :class="
                  (item.key === $route.query.type && hoverIndex === item.key) ||
                  item.active ||
                  isHome === item.key ||
                  hoverIndex === item.key
                    ? ' font-color-yellow-FF9413 font-weight-500'
                    : 'font-color-black-301D09 font-weight-400'
                "
                >{{ $t(`pc_index.${item.name}`) }}</span
              >
              <template
                v-if="
                  item.key !== 'about' &&
                  item.key !== 'home' &&
                  item.key !== 'information' &&
                  item.key !== 'SaaS' &&
                  item.key !== 'app'
                "
              >
                <img
                  v-if="
                    (item.key === $route.query.type &&
                      hoverIndex === item.key) ||
                    item.active ||
                    isHome === item.key ||
                    hoverIndex === item.key
                  "
                  class="nav_icon"
                  src="@/assets/images/arrow_up@2x.png"
                  alt="icon"
                />
                <img
                  v-else
                  class="nav_icon"
                  src="@/assets/images/arrow_down@2x.png"
                  alt="icon"
                />
              </template>
            </div>
          </div>
        </div>
      </div>

      <el-popover
        placement="bottom"
        width="80"
        trigger="click"
        popper-class="popover-langs"
      >
        <slot>
          <ul>
            <li @click="changeLanguage('zh')">
              <img src="@/assets/images/guoqi_zh.png" />&nbsp;&nbsp;简体中文
            </li>
            <li @click="changeLanguage('en')">
              <img src="@/assets/images/guoqi_en.png" />&nbsp;&nbsp;English
            </li>
          </ul>
        </slot>
        <label slot="reference" class="btn-langs">
          <img
            v-if="$i18n.locale == 'English'"
            src="@/assets/images/guoqi_en.png"
          />
          <img v-else src="@/assets/images/guoqi_zh.png" />
          {{ $i18n.locale }}
        </label>
      </el-popover>
      <!-- <div class="header_right flex-row align-center">
      <img class="right_logo" src="@/assets/images/logo@2x.png" alt="" />
      <span class="font-size-18 font-weight-500 font-color-black-301D09">这里是账号昵称</span>
    </div> -->
    </div>

    <!-- 路由导航区域 -->
    <div class="router_box">
      <div class="panpel_box" ref="routerBox">
        <template v-if="showPanpel">
          <!-- 产品中心 -->
          <template
            v-if="activeIndex === 'product' || hoverIndex === 'product'"
          >
            <div class="product_list flex-row">
              <div
                class="p_item"
                v-for="(item, index) in productMenus"
                :key="index"
              >
                <div class="p_i_type">
                  <TitleCategory
                    v-if="item.type"
                    :title="$t(`HeaderBar.${item.type}`)"
                  ></TitleCategory>
                </div>
                <div class="p_list">
                  <div
                    class="l_item flex-row"
                    v-for="(i, index1) in item.children"
                    :key="index1"
                  >
                    <div class="img_box cursor-pointer" @click="linkTo(i)">
                      <img class="img" :src="i.img" alt="icon" />
                    </div>
                    <div class="l_right cursor-pointer" @click="linkTo(i)">
                      <div class="l_title font-size-14 font-weight-400">
                        {{ $t(`HeaderBar.${i.title}`) }}
                      </div>
                      <div class="l_p font-size-10 font-weight-400">
                        {{ $t(`HeaderBar.${i.con}`) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- 解决方案 -->
          <template
            v-if="activeIndex === 'programme' || hoverIndex === 'programme'"
          >
            <div class="programme_list">
              <div
                class="p_item"
                v-for="(item, index) in programmeMenus"
                :key="index"
              >
                <TitleCategory
                  :title="$t(`HeaderBar.${item.type}`)"
                  :titleStyle="programmeStyle"
                ></TitleCategory>
                <div class="p_list flex-row">
                  <div
                    class="l_item flex-row align-center"
                    v-for="(i, index1) in item.children"
                    :key="index1"
                  >
                    <div
                      class="l_title cursor-pointer font-size-14 font-weight-400"
                      @click="linkTo(i)"
                    >
                      {{ $t(`HeaderBar.${i.title}`) }}
                    </div>
                  </div>
                </div>
                <div class="p_list flex-row">
                  <div
                    class="l_item flex-row align-center"
                    v-for="(i, index1) in item.children2"
                    :key="index1"
                  >
                    <div
                      class="l_title cursor-pointer font-size-14 font-weight-400"
                      @click="linkTo(i)"
                    >
                      {{ $t(`HeaderBar.${i.title}`) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- 行业案例 -->
          <template
            v-if="activeIndex === 'industry' || hoverIndex === 'industry'"
          >
            <div class="industry_list flex-row flex-wrap">
              <div
                class="p_item"
                v-for="(item, index) in industryMenus"
                :key="index"
              >
                <TitleCategory
                  :title="$t(`HeaderBar.${item.type}`)"
                  :titleStyle="industryStyle"
                ></TitleCategory>
                <div class="p_list">
                  <div
                    class="l_item flex-row"
                    v-for="(i, index1) in item.children"
                    :key="index1"
                  >
                    <div
                      class="l_title cursor-pointer font-size-14 font-weight-400"
                      style="max-width: 320px"
                      @click="linkTo(i)"
                    >
                      {{ $t(`HeaderBar.${i.title}`) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- 软件下载 -->
          <!-- <template v-if="activeIndex === 'app' || hoverIndex === 'app'">
            <div class="app_list flex-row flex-wrap">
              <div class="p_item" v-for="(item, index) in appMenus" :key="index">
                <TitleCategory :title="item.type" :titleStyle="appStyle"></TitleCategory>
                <div class="p_list">
                  <div class="l_item flex-row" v-for="(i, index1) in item.children" :key="index1">
                    <div class="l_title cursor-pointer font-size-14 font-weight-400" @click="linkTo(i)">
                      {{ i.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template> -->
          <!-- 用户支持 -->
          <template v-if="activeIndex === 'user' || hoverIndex === 'user'">
            <div class="app_list user_list flex-row flex-wrap">
              <div
                class="p_item"
                v-for="(item, index) in userMenus"
                :key="index"
              >
                <TitleCategory
                  :title="$t(`HeaderBar.${item.type}`)"
                  :titleStyle="appStyle"
                ></TitleCategory>
                <div class="p_list">
                  <div
                    class="l_item flex-row"
                    v-for="(i, index1) in item.children"
                    :key="index1"
                  >
                    <div
                      class="l_title font-size-14 font-weight-400"
                      :class="
                        item.type !== '帮助文档'
                          ? 'cursor-pointer'
                          : i.type === 'open'
                          ? 'cursor-pointer'
                          : ''
                      "
                      @click="linkTo(i)"
                    >
                      <template v-if="index == 1">
                        {{ $t(`HeaderBar.${i.title}`) }}
                      </template>
                      <template v-else>
                        {{ i.title }}
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getSoftwareList } from "@/api/api.js";
import TitleCategory from "@/components/TitleCategory.vue";
export default {
  name: "HeaderBar",
  components: {
    TitleCategory,
  },
  data() {
    return {
      navList: [],
      activeIndex: "home", // 当前激活的面板
      hoverIndex: "home", // 当前激活的面板
      showPanpel: false,
      programmeStyle: {
        width: "380px",
        borderColor: "rgba(29,29,31,0.2)",
        color: "#1D1D1F",
        fontSize: "16px",
        fontWeight: 400,
      },
      industryStyle: {
        width: "320px",
        borderColor: "rgba(29,29,31,0.2)",
        color: "#1D1D1F",
        fontSize: "16px",
        fontWeight: 400,
      },
      appStyle: {
        width: "240px",
        borderColor: "rgba(29,29,31,0.2)",
        color: "#1D1D1F",
        fontSize: "16px",
        fontWeight: 400,
      },
      // 产品中心
      productMenus: [
        {
          type: "product_type1_title",
          children: [
            {
              img: require("../assets/images/productImg/cpzx_nav_1@2x.png"),
              title: "product_type1_item1_title",
              con: "product_type1_item1_content",
              path: "/productSzhjsgl",
            },
            {
              img: require("../assets/images/productImg/cpzx_nav_2@2x.png"),
              title: "product_type1_item2_title",
              con: "product_type1_item2_content",
              path: "/zhgd",
            },
            {
              img: require("../assets/images/productImg/cpzx_nav_3@2x.png"),
              title: "product_type1_item3_title",
              con: "product_type1_item3_content",
              path: "/zngc",
            },
          ],
        },
        {
          type: "product_type2_title",
          children: [
            {
              img: require("../assets/images/productImg/cpzx_nav_4@2x.png"),
              title: "product_type2_item1_title",
              con: "product_type2_item1_content",
              path: "/airoad",
            },
            {
              img: require("../assets/images/productImg/cpzx_nav_5@2x.png"),
              title: "product_type2_item2_title",
              con: "product_type2_item2_content",
              path: "/eicad",
            },
            {
              img: require("../assets/images/productImg/cpzx_nav_6@2x.png"),
              title: "product_type2_item3_title",
              con: "product_type2_item3_content ",
              path: "/ticad",
            },
          ],
        },
        {
          type: "",
          children: [
            {
              img: require("../assets/images/productImg/cpzx_nav_7@2x.png"),
              title: "product_type2_item4_title",
              con: "product_type2_item4_content",
              path: "/vrRoad",
            },
            {
              img: require("../assets/images/productImg/Report3D.png"),
              title: "product_type2_item5_title",
              con: "product_type2_item5_content",
              path: "/Report3D",
            },
            // {
            //   img: require("../assets/images/productImg/cpzx_nav_8@2x.png"),
            //   title: "ZicCity紫城数字化汇报系统",
            //   con: "基于UE5游戏引擎研发的、面向多类型工程的汇报工具",
            // },
          ],
        },
      ],
      // 解决方案
      programmeMenus: [
        {
          type: "solution_type1_title",
          children: [
            {
              title: "solution_type1_item1_title",
              path: "/virtualConstruction",
            },
            {
              title: "solution_type1_item2_title",
              path: "/vr",
            },
            {
              title: "solution_type1_item3_title",
              path: "/photo",
            },
          ],
          children2: [
            {
              title: "solution_type1_item4_title",
              path: "/laboratory",
            },
          ],
        },
      ],
      // 行业案例
      industryMenus: [
        {
          type: "cases_type1_title",
          children: [
            {
              title: "cases_type1_item1_title",
              path: "/crossRiverBridge",
            },
          ],
        },
        {
          type: "cases_type2_title",
          children: [
            {
              title: "cases_type2_item1_title",
              path: "/expresswayHw",
            },
          ],
        },
        {
          type: "cases_type3_title",
          children: [
            {
              title: "cases_type3_item1_title",
              path: "/riverTunnelJyjj",
            },
            {
              title: "cases_type3_item2_title",
              path: "/riverTunnelHt",
            },
          ],
        },
        {
          type: "cases_type4_title",
          children: [
            {
              title: "cases_type4_item1_title",
              path: "/tlAvenue",
            },
          ],
        },
        {
          type: "cases_type5_title",
          children: [
            {
              title: "cases_type5_item1_title",
              path: "/hsyc",
            },
            {
              title: "cases_type5_item2_title",
              path: "/fhz",
            },
          ],
        },
      ],
      // 软件下载
      // appMenus: [
      //   {
      //     type: "EICAD数字设计系列软件",
      //     children: [
      //       {
      //         title: "EICAD V5.0 For AutoCAD",
      //         path: "/download",
      //       },
      //       {
      //         title: "EICAD V5.0 For 中望CAD",
      //         path: "/download",
      //       },
      //       {
      //         title: "EICAD V3.0",
      //         path: "/download",
      //       },
      //       {
      //         title: "EICAD3设计查询程序",
      //         path: "/download",
      //       },
      //       {
      //         title: "EICAD网络服务器安装",
      //         path: "/download",
      //       },
      //     ],
      //   },
      //   {
      //     type: "AIRoad快速方案研究系统",
      //     children: [
      //       {
      //         title: "AIRoad V1.0",
      //         path: "/download",
      //       },
      //     ],
      //   },
      //   {
      //     type: "其他",
      //     children: [
      //       {
      //         title: "EIRVT 2018版",
      //         path: "/download",
      //       },
      //       {
      //         title: "道路勘测模拟训练场VRS",
      //         path: "/download",
      //       },
      //       {
      //         title: "RSS3D道路标准横断面设计与模拟软件",
      //         path: "/download",
      //       },
      //       {
      //         title: "城市轨道交通线路设计系统 TICAD4",
      //         path: "/download",
      //       },
      //     ],
      //   },
      // ],
      // 用户支持
      userMenus: [],
      isHome: false,
    };
  },
  computed: {},
  watch: {
    $route: {
      handler(to, form) {
        this.isHome = to.fullPath === "/pc_index" ? "home" : "";
        this.hoverIndex = to.query.type;
      },
      immediate: true,
    },
    "$i18n.locale": function () {
      this.getInIt();
      this.checkSoftwareList();
    },
  },
  created() {
    this.getInIt();
    this.checkSoftwareList();
  },
  mounted() {
    let el1 = this.$refs["routerBox"];
    // let el2 = this.$refs["headerCenter"];
    el1.addEventListener("mouseleave", this.otherClose);
    // el2.addEventListener("mouseleave", this.otherClose);
  },
  methods: {
    // 国际化 语言切换
    changeLanguage(type) {
      if (type == "zh") {
        this.$i18n.locale = "简体中文";
      } else if (type == "en") {
        this.$i18n.locale = "English";
      }
      localStorage.setItem("dnn-cw-lang", this.$i18n.locale);
      this.checkSoftwareList();
    },

    // 获取用户支持中的 产品列表
    checkSoftwareList() {
      getSoftwareList({
        language: this.$i18n.locale == "English" ? "en" : "zh",
      }).then((res) => {
        if (res.code === 200) {
          let helpList = [];
          let logList = [];
          let language = this.$i18n.locale == "English" ? "en" : "zh";
          res.data.map((item) => {
            // console.log('checkSoftwareList...', item)
            helpList.push({
              title: item.alias + " 帮助文档",
              type: "open",
              path: "/dnnweb/helpDocuments?soft=" + item.alias +'&language=' + language,
            });
            logList.push({
              title: item.alias + " 更新日志",
              type: "open",
              path: "/dnnweb/updateLog?softwareId=" + item.id,
            });
          });
          this.userMenus = [
            {
              type: "support_type1_title",
              children: helpList,
            },
            {
              type: "support_type2_title",
              children: [
                {
                  title: "support_type2_item1_title",
                  path: "/demonstrationTeaching",
                },
              ],
            },
            {
              type: "support_type3_title",
              children: logList,
            },
          ];
        }
      });
    },

    linkTo(item) {
      console.log(item);
      if (item.path && item.type !== "open") {
        if (this.$route.path !== item.path) {
          this.activeIndex = this.hoverIndex;
          this.$router.push({
            path: item.path,
            query: { type: this.activeIndex },
          });
          // this.$uniFun.openUrl(`/dnnweb/${item.path}?type=${this.activeIndex}`)
        }
        this.showPanpel = false;
      } else if (item.type === "open") {
        window.open(item.path);
      }
    },
    /*
     * @Description: 关闭弹框
     * @MethodAuthor:  liulian
     * @Date: 2023-06-29 11:53:35
     * @param: ''
     */
    otherClose(e) {
      this.showPanpel = false;
      // let el1 = this.$refs["routerBox"];
      // let el2 = this.$refs["headerCenter"];
      // if (el1 || el2) {
      //   if (
      //     !this.$refs["routerBox"].contains(e.target) &&
      //     !this.$refs["headerCenter"].contains(e.target)
      //   ) {
      //     this.showPanpel = false;
      //   }
      // }
    },
    /*
     * @Description: 鼠标悬浮事件
     * @MethodAuthor:  liulian
     * @Date: 2023-07-14 11:18:03
     * @param: ''
     */
    mouseenterEvent(item) {
      this.hoverIndex = item.key;
      this.activeIndex = "";
      this.showPanpel = true;
      this.isHome = false;
    },
    /*
     * @Description: 点击导航
     * @MethodAuthor:  liulian
     * @Date: 2023-06-28 17:18:32
     * @param: ''
     */
    clickNav(item) {
      this.activeIndex = item.key;
      this.hoverIndex = item.key;
      this.showPanpel = true;
      // this.navList.map((i) => {
      //   this.$set(i, "active", i.key === item.key)
      // })

      if (item.path && item.key !== "SaaS") {
        if (this.$route.path !== item.path) {
          // this.$router.push({ path: item.path, query: { type: item.key } });
          if (item.key === "information") {
            this.$uniFun.openUrl(`/dnnweb${item.path}&type=${item.key}`);
          } else {
            this.$uniFun.openUrl(`/dnnweb${item.path}?type=${item.key}`);
          }
        }
        this.showPanpel = false;
      } else if (item.key === "SaaS") {
        window.open(item.path);
      }
    },
    getInIt() {
      this.navList = [
        {
          name: "Home",
          key: "home",
          path: "/pc_index",
        },
        {
          name: "Product",
          key: "product",
        },
        {
          name: "Solution",
          key: "programme",
        },
        {
          name: "Cases",
          key: "industry",
        },
        {
          name: "NewsVideo",
          key: "information",
          path: "/information?class=-1",
        },
        {
          name: "Download",
          key: "app",
          path: "/download",
        },
        {
          name: "Support",
          key: "user",
        },
        {
          name: "AboutUs",
          key: "about",
          path: "/about",
        },
        {
          name: "SaaS",
          key: "SaaS",
          path: "https://www.dnnbim.com/login",
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.header_contaienr {
  height: 80px;
  background: #ffffff;
  line-height: 80px;
  padding-left: 64px;
  // padding-right: 64px;
  width: 100vw;
  flex-wrap: nowrap;
  z-index: 999999999;
  .header_left {
    height: 100%;
    width: 168px;

    .left_name {
      white-space: nowrap;
    }
  }
  .header_center {
    flex: 1;
    // max-width: 1390px;
    position: relative;

    .navs {
      height: 100%;
      .nav_item {
        // width: 160px;
        // padding-left: 28px;
        // padding-right: 28px;
        padding-left: 1%;
        padding-right: 1%;
        flex: 1;

        position: relative;
        .nav_name {
          white-space: nowrap;
        }
        .nav_icon {
          width: 24px;
          height: 24px;
          margin-left: 8px;
        }
      }
      .nav_active::after {
        content: "";
        width: 100%;
        height: 4px;
        background-color: #fc8f01;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      .nav_item:nth-child(1) {
        // width: 140px;
        // padding-left: 46px;
        // padding-right: 46px;
        // margin-left: 131px;
        margin-left: 7%;
      }
      .nav_item:last-child {
        // width: 164px;
        // padding-right: 34px;
        // padding-left: 58px;
        margin-right: 143px;
      }
    }
  }
  .header_logo {
    width: 36.88px;
    height: 40px;
    margin-right: 8px;
  }
  .header_right {
    height: 100%;
    width: 168px;
    justify-content: flex-end;
    white-space: nowrap;

    .right_logo {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }
}
.router_box {
  width: 100%;
  .panpel_box {
    width: 1316px;
    background-color: #ffffff;
    margin: auto;
    // padding-left: 138px;
    // padding-right: 138px;
    .product_list {
      padding-bottom: 54px;
      padding-top: 32px;
      padding-left: 65px;

      .p_item {
        margin-right: 48px;

        .img_box {
          padding-right: 12px;
          .img {
            width: 40px;
            height: 40px;
            background: #dadada;
            border-radius: 8px;

            border-radius: 8px;
          }
        }
      }
      .p_i_type {
        min-height: 24px;
      }
      .p_list {
        padding-top: 25px;
        .l_item {
          margin-bottom: 32px;
          max-width: 420px;
          height: 54px;

          .l_title {
            color: #1d1d1f;
            line-height: 1.2;
          }
          .l_p {
            color: rgba(29, 29, 31, 0.6);
            margin-top: 6px;
            line-height: 1.4;
          }
        }
        .l_right {
          display: inline-block;
        }
        .l_item:last-child {
          margin-bottom: unset;
        }
      }
    }
    .programme_list {
      min-height: 320px;
      padding-top: 32px;
      padding-left: 138px;
      padding-right: 138px;

      .p_list {
        padding-top: 22px;
        .l_item {
          color: rgba(29, 29, 31, 0.6);
          margin-right: 40px;
        }
      }
    }
    .industry_list {
      padding-bottom: 77px;
      gap: 40px;
      padding-top: 32px;
      padding-left: 138px;
      padding-right: 138px;

      .p_list {
        padding-top: 14px;

        color: rgba(29, 29, 31, 0.6);
        .l_item {
          margin-top: 14px;
        }
        .l_item:nth-child(1) {
          margin-top: unset;
        }
      }
    }
    .app_list {
      padding-bottom: 116px;
      gap: 155px;
      padding-top: 32px;
      padding-left: 138px;
      padding-right: 138px;

      .p_list {
        padding-top: 14px;

        color: rgba(29, 29, 31, 0.6);
        .l_item {
          margin-top: 14px;
        }
        .l_item:nth-child(1) {
          margin-top: unset;
        }
      }
    }
    .user_list {
      padding-bottom: 172px;
      padding-top: 32px;
      padding-left: 138px;
      padding-right: 138px;
    }
  }
}

.popover-langs {
  position: absolute;
  right: 15px;
  top: 0;
  li {
    cursor: pointer;
  }
  li:first-child {
    margin-bottom: 10px;
  }
  img {
    margin-right: 2px;
    vertical-align: middle;
  }
}
.btn-langs {
  margin-right: 30px;
  cursor: pointer;
  img {
    margin-right: 2px;
    vertical-align: middle;
  }
}
</style>


const env = process.env;
let baseURL;
if (env.NODE_ENV == 'development') { // 谷中
  baseURL = 'http://680052ftiz73.vicp.fun';
}
else if (env.NODE_ENV == 'test') {  // 测试
  baseURL = 'http://218.94.57.151:8001/dnnWebManage/';
}
else if (env.NODE_ENV == 'production') { // 正式
  baseURL = 'https://dev.eicad.net/dnnWebManage/';
}

export { baseURL }
<template>
  <div>
    <div id="top">
      <div id="nav">
        <h1>
          <img :src="`${publicPath}static/images/logo.png`" />
          <a href="http://www.jsdnn.com" target="_blank">江苏狄诺尼</a>
          <i />
          <a href="index.html"><span id="softName" />-帮助</a>
        </h1>
      </div>
      <div class="search-box input-default">
        <span id="ruler" />
        <input id="search" type="text" value="搜索问题关键字，找到答案" />
        <i class="iconfont icon-sousuo search-icon" />
        <i class="iconfont icon-guanbi search-clear" />
      </div>
    </div>
    <!-- 左侧菜单 -->
    <div id="menu">
      <h2>
        <span>目录</span>
        <i class="iconfont icon-caidan menuIcon" @click="expandAll()" />
      </h2>
      <!-- 菜单内容 -->
      <ul id="menuTree" class="ztree showIcon" />
    </div>
    <!-- 右侧内容 -->
    <div id="main">
      <!-- 菜单对应的内容页面 -->
      <div id="content" />

      <!-- 搜索结果 -->
      <div id="search_page" style="display: none">
        <span class="iconfont icon-iconfront-1 search_page_icon" />
        <div id="appendInfo" class="appendInfo" />

        <!-- 分页 -->
        <div id="paging" />
      </div>
      <!-- 底部信息 -->
      <div id="footer">
        <p>
          江苏狄诺尼信息技术有限责任公司<a
            href="http://www.beian.miit.gov.cn/"
            target="_blank"
            >苏ICP备18062906号</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// ZTree配置参数
let zTree = null;
let expandFlag = true;
import { baseURL } from "@/utils/env.js";
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  mounted() {
    // config.js
    // var baseUrl = "http://192.168.100.230:8090/Delauney/interface"; //本地
    const that = this;

    // const baseUrl = "http://106.14.238.88:8080/delauney/interface"; // 线上
    const baseUrl = baseURL;
    let soft = null;
    let cmd = null;
    let module = null;
    let softId = null;
    let keyword = ""; // 查询关键词
    let pageNumber = 1; // 当前页数
    const pageSize = 5; // 每页显示条数
    let pageAll = 0; // 总页数
    let sumCount = 0; // 搜索总条数
    let isFirst = true; // 分页初始化
    let searchInfo = ""; // 搜索内容
    const $ = window.$ || {};
    const setting = {
      view: {
        showLine: false,
        showIcon: true,
        selectedMulti: false,
        dblClickExpand: false,
        addDiyDom,
      },
      data: {
        simpleData: {
          enable: true,
        },
        key: {
          name: "label",
        },
      },
      callback: {
        onClick,
      },
    };
    // 获取URL参数
    function getQueryString(name) {
      const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, "i");
      const r = window.location.search.slice(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      } else {
        return null;
      }
    }

    // 修改URL参数
    function changeURLArg(url, arg, arg_val) {
      const pattern = `${arg}=([^&]*)`;
      const replaceText = `${arg}=${arg_val}`;
      if (url.match(pattern)) {
        let tmp = `/(${arg}=)([^&]*)/gi`;
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
      } else {
        if (url.match("[?]")) {
          return `${url}&${replaceText}`;
        } else {
          return `${url}?${replaceText}`;
        }
      }
    }

    // 更新URL地址参数参数
    function updateUrl() {
      console.log("更新URL地址参数参数");
      let url = window.location.href;
      if (soft) {
        url = changeURLArg(url, "soft", soft);
      }
      if (module) {
        url = changeURLArg(url, "module", module);
      }
      if (cmd) {
        url = changeURLArg(url, "cmd", cmd);
      }
      window.history.pushState({}, "", url);
    }

    // 添加节点自定义样式
    function addDiyDom(treeId, treeNode) {
      const spaceWidth = 5;
      const switchObj = $(`#${treeNode.tId}_switch`);
      const icoObj = $(`#${treeNode.tId}_ico`);
      switchObj.remove();
      icoObj.before(switchObj);

      if (treeNode.level > 1) {
        const spaceStr = `<span style='display: inline-block;width:${
          spaceWidth * treeNode.level
        }px'></span>`;
        switchObj.before(spaceStr);
      }
    }

    // 树节点点击事件 加载文档
    function onClick(event, treeId, treeNode, clickFlag) {
      // console.log("树节点点击事件 加载文档===>");
      console.log("treeNode", treeNode);
      // 展开节点
      zTree.expandNode(treeNode);

      // 更新URL参数
      if (treeNode.cmd && treeNode.level > 0) {
        cmd = treeNode.cmd;
      } else {
        cmd = "-1";
      }

      module = getNodeRootCMD(treeNode);
      updateUrl();
      // console.log(treeNode);
      // 加载内容页面
      const helpid = treeNode.id;
      if (typeof helpid === "undefined" || helpid == null || helpid === "") {
        return;
      }

      // 弹出加载提示
      const loading = that.getLoading();
      $.get(
        `${baseUrl}/client/softwareDetail/clientSoftwareDetailSelectById/${helpid}`,
        (data) => {
          // 关闭加载提示
          loading.close();
          if (data.code !== 200 || !data.data) {
            return;
          }

          $("#content").html(
            `<h2>${treeNode.label}</h2>${data.data ? data.data.content : ""}`
          );
        }
      );
      return false;
    }

    // 取得节点的父级根节点的CMD
    function getNodeRootCMD(node) {
      console.log("取得节点的父级根节点的CMD==>");
      while (true) {
        const parent = node.getParentNode();
        if (parent == null) {
          let mCmd = node.cmd;
          if (mCmd === "" || mCmd == null) {
            mCmd = -1;
          }
          return mCmd;
        }
        node = parent;
      }
    }

    // 文档加载完成
    (function () {
      // 软件名称
      soft = getQueryString("soft");
      // 获取url后id参数
      cmd = getQueryString("cmd");
      // 模块id
      module = getQueryString("module");
      // 版本号
      let version = getQueryString("version");
      // 语言
      let language = getQueryString("language");
      const tbSoftwareLoading = that.getLoading();
      // 根据软件缩写名称请求菜单
      // alias  别名  ,language   语言,version   版本
      const data = {
        alias: soft,
        language: language ? language : 'zh',
        version: version ? version : ''
      }
      $.ajax({
        url: `${baseUrl}/client/software/softwareSelectByAlias`,
        type: 'get',
        data,
        success: (data,status,xhr) => {
          tbSoftwareLoading.close();
          if (data.code !== 200) {
            return;
          }

          const softData = data.data;
          softId = softData.id;
          if (soft == null) {
            soft = softData.alias;
            updateUrl();
          }
          $("#softName").html(softData.name); // 显示软件名称

          const menuNodes = JSON.parse(softData.menu);

          // 初始化ZTree
          zTree = $.fn.zTree.init($("#menuTree"), setting, menuNodes);

          // 根据参数打开默认文档
          let parentNode = null;
          let results = [];
          // 如果指定模块
          if (module != null) {
            const results = zTree.getNodesByParam("cmd", module, null);
            for (let i = 0; i < results.length; i++) {
              // 搜索一级菜单
              if (results[i].level === 0) {
                parentNode = results[i];
                break;
              }
            }
          }

          // 如果指定命令
          if (cmd != null) {
            // 搜索指定cmd
            results = zTree.getNodesByParam("cmd", cmd, parentNode);
            if (results.length !== 0) {
              // console.log(results);
              // 选中节点
              zTree.selectNode(results[0], false, false);
              // 触发点击事件
              onClick(null, -1, results[0], -1);
            } else {
              // 展开模块
              if (parentNode) {
                zTree.selectNode(parentNode, false, false);
                zTree.expandNode(parentNode);
              }
            }
          } else {
            // 展开模块
            if (parentNode) {
              zTree.selectNode(parentNode, false, false);
              zTree.expandNode(parentNode);
            }
          }
        },
        error: (e) => {
          console.log('ajax error=>',e);
          tbSoftwareLoading.close();
        }
      })
    })();

    // / ////////////////////////搜索框/////////////////////////////
    // 输入信息总长度
    String.prototype.visualLength = function () {
      const ruler = $("#ruler");
      ruler.text(this);
      return ruler[0].offsetWidth;
    };

    // 搜索框清除图标
    $(".search-clear").click(function () {
      searchInfo = "";

      $(this).siblings("#search").val("搜索问题关键字，找到答案");
      $(this).parent(".search-box").addClass("input-default");
      $(this).hide();
      $("#main").removeClass("searchBox search_page_con_box");
      $("#search_page").css("display", "none");
    });

    // 去除首尾空格
    $("#search").change(function () {
      $(this).val($.trim($(this).val()));
    });

    // 获得焦点时
    $("#search").focus(function () {
      const oldValue = $(this).val();
      if (oldValue === this.defaultValue) {
        $(this).val("");
        $(this).parent(".search-box").removeClass("input-default");
      }
      $(this).siblings(".search-clear").hide();
    });

    // 失去焦点时
    $("#search").blur(function () {
      // searchInfo = "";

      const oldValue = $(this).val();
      // 输入框文本长度
      const text = oldValue;
      const len = text.visualLength();

      if (oldValue === "") {
        $(this).val(this.defaultValue);
        $(this).parent(".search-box").addClass("input-default");
        $(this).siblings(".search-clear").hide();
        $("#main").removeClass("searchBox search_page_con_box");
        $("#search_page").css("display", "none");
      } else {
        const width = `${len / 2 + 16}px`;
        $(this).siblings(".search-clear").css({
          "margin-left": width,
          display: "block",
        });
      }
    });

    // enter键
    $("#search").keydown(function () {
      searchInfo = "";
      $("#appendInfo").html("");
      $("#paging").hide();
      const oldValue = $(this).val();

      if (event.keyCode === 13) {
        $("#search").blur();

        if (oldValue !== "") {
          $("#main").addClass("searchBox");
          $("#search_page").css("display", "block");

          // 查询关键词
          keyword = oldValue;
          isFirst = true;
          pageNumber = 1; // 当前页数
          pageAll = 0; // 总页数
          sumCount = 0; // 搜索总条数
          // 搜索页面
          getList();
          if ($("#main").hasClass("search_page_con_box")) {
            $("#main").removeClass("search_page_con_box");
            $(".search_page_icon")
              .removeClass("icon-iconfront-")
              .addClass("icon-iconfront-1");
          }
        }
      }
    });

    // 搜索页面
    function getList() {
      // 弹出加载提示
      const loading = that.getLoading();
      $.get(
        `${baseUrl}/client/software/helpSearch`,
        { keyWord: keyword, softwareId: softId, pageNum: pageNumber, pageSize },
        (data) => {
          if (data.code !== 200) {
            return;
          }
          sumCount = data.data.total;
          const infoList = data.data.rows; // 返回的条数

          $("#appendInfo").html("");

          if (sumCount > 0) {
            pageAll = Math.ceil(sumCount / pageSize); // Math.ceil向上取整数
            searchInfo += `<label class='info'>搜索结果：${sumCount}，共${pageAll}页</label>`;
            searchInfo += "<ul>";

            for (let i = 0; i < infoList.length; i++) {
              let labelStr = "";

              const results = zTree.getNodesByParam(
                "id",
                infoList[i].menuId,
                null
              );
              if (results.length > 0) {
                labelStr = results[0].label;
              }

              searchInfo +=
                `<li data-id=${infoList[i].menuId}>` +
                `<h4>${labelStr}</h4>` +
                `<p>${infoList[i].line}</p>` +
                `</li>`;
            }
            searchInfo += "</ul>";

            $("#appendInfo").append(searchInfo);

            if (isFirst) {
              getPaging();
            }
          } else {
            $("#appendInfo").append(`未找到任何有关 <b>${keyword}</b> 的结果`);
            $("#paging").css("display", "none");
          }

          // 关闭加载提示
          loading.close();
        }
      );
    }

    // 分页
    function getPaging() {
      $("#paging").pagination({
        keepShowPN: true, // 一直显示上一页下一页
        totalData: sumCount, // 总条数
        showData: pageSize, // 每页显示的条数
        coping: true, // 是否开启首页和末页
        isHide: true, // 总页数为0或1时隐藏分页控件
        // mode: 'fixed', //固定页码按钮数量
        prevCls: "prev", // 上一页class
        nextCls: "next", // 下一页class
        callback(api) {
          searchInfo = "";
          // console.log(searchInfo)
          // console.log(api.getCurrent()) //获得当前页码
          pageNumber = api.getCurrent();
          isFirst = false;
          getList();
        },
      });
      // isFirst = false;
    }

    // 搜索页面影藏至右侧
    $(".search_page_icon").click(function () {
      if ($("#main").hasClass("search_page_con_box")) {
        $(this).removeClass("icon-iconfront-").addClass("icon-iconfront-1");
        $("#main").removeClass("search_page_con_box");
      } else {
        $(this).removeClass("icon-iconfront-1").addClass("icon-iconfront-");
        $("#main").addClass("search_page_con_box");
      }
      // $(this).toggleClass("icon-iconfront-1");
      // $(this).toggleClass("icon-iconfront-");
      // $("#main").toggleClass("search_page_con_box");
    });

    // 搜索点击
    $("#appendInfo").on("click", "li", function () {
      // alert("====")
      const id = $(this).attr("data-id");
      // var parentNode = null;
      const results = zTree.getNodesByParam("id", id, null);
      for (let i = 0; i < results.length; i++) {
        // 选中节点
        zTree.selectNode(results[i], false, false);
        // 触发点击事件
        onClick(null, -1, results[i], -1);
        break;
      }
    });
  },
  methods: {
    getLoading() {
      const loading = this.$message({
        message: this.$t(`common.Loading`),
        type: "success",
        iconClass: "el-icon-loading",
        duration: 0,
      });
      return loading;
    },
    expandAll() {
      if (zTree) {
        zTree.expandAll(expandFlag);
        expandFlag = !expandFlag;
      }
    },
  },
};
</script>
